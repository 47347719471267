import React, { useState, useEffect } from "react";
import TooltipComponent from "@components/TooltipComponent";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const EditableInputComponent = ({
  updatedValue,
  onSave,
  hasToolTip,
  hasLabel,
  className,
  toolTipMessage,
  onLabelClick,
  originalAmount,
  labelValue,
}) => {
  const { t: translate } = useTranslation();

  const [value, setValue] = useState(updatedValue);
  const [originalValue, setOriginalValue] = useState(updatedValue);
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    setValues(value);
  };

  const setValues = (newValue, withSet = false) => {
    if (withSet) {
      setValue(newValue);
    }
    setOriginalValue(newValue);
    onSave(newValue);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setValue(originalValue);
  };

  return (
    <div>
      {hasLabel && (
        <label className="font-semibold flex items-center gap-2 text-sm text-gray-500 rtl:text-right w-full">
          {labelValue}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-5"
            data-tooltip-id="tooltip-info-destination"
            // data-tooltip-content="source"
            // data-tooltip-place="top"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
            />
          </svg>
        </label>
      )}
      {hasToolTip && (
        <TooltipComponent
          anchorElement="tooltip-info-destination"
          toolTipMessage={toolTipMessage}
          position="top"
          className="max-w-96"
        />
      )}
      <div className={clsx("flex gap-2 items-center mt-2")}>
        <input
          className="border-2 border-gray-300 rounded-md p-2 w-full"
          type="number"
          value={value}
          readOnly={!isEditing}
          onChange={(e) => {
            const value = e.target.value;
            if (value === "" || (Number(value) >= 0 && !value.includes("-"))) {
              setValue(value);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "-") {
              e.preventDefault();
            }
          }}
          min="0"
        />
        <div className="min-w-[56px] flex justify-center">
          {isEditing ? (
            <div className="flex gap-2">
              <button onClick={handleSaveClick}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="green"
                  className="size-6 "
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m4.5 12.75 6 6 9-13.5"
                  />
                </svg>
              </button>
              <button onClick={handleCancelClick}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="red"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          ) : (
            <button onClick={handleEditClick}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
      <span
        onClick={() => {
          setIsEditing(false);
          setValues(originalAmount || "", true);
        }}
        className={clsx(
          "text-xs cursor-pointer text-primary-600",
          originalAmount === value && "opacity-0"
        )}
      >
        {translate("confirmation-popup.originalAmount")}
        {originalAmount || 0}
      </span>
    </div>
  );
};

export default EditableInputComponent;
