import React from "react";
import {
  MESSAGE_TYPE,
  MAJ_SUCCESS,
  MAJ_SUCCESS_INFO,
  MAJ_SUCCESS_WARN,
  MAJ_ERR,
  MAJ_VAL_ERR,
  MessageTypes,
} from "@utils/constants";
import clsx from "clsx";
import GlobalStore from "@stores/GlobalStore";

import { apiHelper } from "@helpers/apiHelper";
import { toast } from "react-toastify";

export const DIGIT_DECIMAL_POINT = 2;

export const fetchDynamicFilters = async (context, callback = () => {}) => {
  const externalurl = `/api/v1/Common/GetFilters`;

  const activityTag = "GetFilters";

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: {
      TenantCode: null,
      Context: context,
    },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data.Filters);
      return data.Filters;
    })

    .catch((error) => {
      return {};
    });
};
export const fetchDynamicTags = async (
  context,
  tags = [],
  callback = () => {}
) => {
  const externalurl = `/api/v1/Common/GetListsByTags`;
  const activityTag = "GetFilters";

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: {
      TenantCode: null,
      Context: context,
      Tags: tags,
    },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data.Lists);
      return data.Lists;
    })

    .catch((error) => {
      return {};
    });
};
export const executeCorrectiveActions = async ({
  ActionTypeTag = "",
  ExceptionRecords = [],
  ActionReasonTag = "",
  ActionReasonDescription = "",
  AdjustedSourceAmount = null,
  AdjustedDestinationAmount = null,
  callback = () => {},
  store,
}) => {
  const externalurl = `/api/v1/ControlRoom/ExecuteCorrectiveActions`;
  const activityTag = "ExecuteCorrectiveActions";

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: {
      TenantCode: null,
      ActionTypeTag,
      ExceptionRecords,
      ActionReasonTag,
      ActionReasonDescription,
      AdjustedSourceAmount,
      AdjustedDestinationAmount,
    },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      store.loading = false;
      return data;
    })

    .catch((error) => {
      store.loading = false;
      return {};
    });
};

export const getDataAcquisitionInstanceLog = async ({
  DataAcquisitionInstanceId,
  callback = () => {},
  store,
}) => {
  const externalurl = `/api/v1/DataAcquisition/GetDataAcquisitionInstanceLog`;
  const activityTag = "GetDataAcquisitionInstanceLog";

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: {
      DataAcquisitionInstanceId,
    },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      store.loading = false;
      return data;
    })

    .catch((error) => {
      store.loading = false;
      return {};
    });
};
export const getReconciliationRunLog = async ({
  ReconciliationRunId,
  callback = () => {},
  store,
}) => {
  const externalurl = `/api/v1/Reconciliation/GetReconciliationRunLog`;
  const activityTag = "GetReconciliationRunLog";

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: {
      ReconciliationRunId,
    },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      store.loading = false;
      return data;
    })

    .catch((error) => {
      store.loading = false;
      return {};
    });
};
export const getReconciliationSummary = async (
  ReconciliationSummaryTag,
  callback = () => {}
) => {
  const externalurl = `/api/v1/ControlRoom/GetReconciliationSummary`;
  const activityTag = "GetReconciliationSummary";

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: {
      TenantCode: null,
      ReconciliationSummaryTags: [ReconciliationSummaryTag],
    },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      return data;
    })

    .catch((error) => {
      return {};
    });
};
export const getCutoffSettings = async (callback = () => {}) => {
  const externalurl = `/api/v1/BusinessDay/GetCutoffSettings`;
  const activityTag = "GetCutoffSettings";

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: {
      TenantCode: null,
    },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      return data;
    })

    .catch((error) => {
      return {};
    });
};
export const skipNextCutoff = async (callback = () => {}) => {
  const externalurl = `/api/v1/BusinessDay/SkipNextCutoff`;
  const activityTag = "SkipNextCutoff";

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: {
      TenantCode: null,
    },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      return data;
    })

    .catch((error) => {
      return {};
    });
};

export const getAttemptDetails = async (
  id,
  sentActivityTag,
  callback = () => {}
) => {
  const externalurl = `/api/v1/ControlRoom/GetReconciliationAttempts`;
  const activityTag = sentActivityTag;

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: {
      PromotedRecordId: sentActivityTag === "GetInTransitAttempts" ? id : null,
      MatchingRecordId: sentActivityTag === "GetExceptionAttempts" ? id : null,
      IsLatestAttempt: false,
    },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      return data;
    })

    .catch((error) => {
      return {};
    });
};

export const getExceptionDetails = async (id, callback = () => {}) => {
  const externalurl = `/api/v1/ControlRoom/GetExceptionDetails`;
  const activityTag = "GetExceptionDetails";

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: { MatchingRecordId: id },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      return data;
    })

    .catch((error) => {
      return {};
    });
};

export const getTenantHierarchy = async (callback = () => {}) => {
  const externalurl = `/api/v1/Membership/GetTenantHierarchy`;
  const activityTag = "GetTenantHierarchy";
  const { ttpTenantCode } = GlobalStore;

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: { TenantCode: "" },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      return data;
    })

    .catch((error) => {
      return {};
    });
};

export const resolvePickedRecords = (selectedIDs, outstandingRecords) => {
  const pickedRecords = [];
  selectedIDs?.forEach((id) => {
    const record = outstandingRecords.find(
      (record) =>
        record.SourcePromotedRecordIds?.includes(id) ||
        record.DestinationPromotedRecordIds?.includes(id)
    );
    if (record) {
      const foundRecord = pickedRecords.find(
        (singleRecord) =>
          singleRecord.MatchingRecordId === record.MatchingRecordId
      );
      if (foundRecord) {
        foundRecord.PromotedRecordIds.push(id);
      } else {
        pickedRecords.push({
          MatchingRecordId: record.MatchingRecordId,
          PromotedRecordIds: [id],
        });
      }
    }
  });
  return pickedRecords;
  // return outstandingRecords.filter((record) => PickedIDS.includes(record.MatchingRecordId));
};

export const PreviewManualMatching = async (
  outstandingRecords,
  exceptionRecord,
  exceptionSourceIDs,
  outstandingDestionationIDs,
  callback = () => {}
) => {
  const externalurl = `/api/v1/ControlRoom/PreviewManualMatching`;

  const activityTag = "PreviewManualMatching";

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: {
      ExceptionAtHand: resolvePickedRecords(
        exceptionSourceIDs,
        exceptionRecord
      )[0],
      PickedRecords: resolvePickedRecords(
        outstandingDestionationIDs,
        outstandingRecords.data
      ),
      TenantCode: null,
    },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      return data;
    })

    .catch((error) => {
      return {};
    });
};

export const GetReportTypes = async (callback = () => {}) => {
  const externalurl = `/api/v1/Reports/GetReportTypes`;
  const activityTag = "GetReportTypes";

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: {
      TenantCode: null,
    },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      return data;
    })

    .catch((error) => {
      return {};
    });
};
export const CommitManualMatching = async (
  ManualMatchingRequestId,
  ActionReasonTag,
  ActionReasonDescription,
  correctiveAction,
  callback = () => {}
) => {
  const externalurl = `/api/v1/ControlRoom/CommitManualMatching`;
  const activityTag = "CommitManualMatching";

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: {
      ManualMatchingRequestId: ManualMatchingRequestId,
      ActionReasonTag: ActionReasonTag,
      ActionReasonDescription: ActionReasonDescription,
      CorrectionResults: correctiveAction,
      TenantCode: null,
    },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      return data;
    })

    .catch((error) => {
      return {};
    });
};
export const RollbackReconciliationRun = async (
  ReconciliationRunId,
  callback = () => {},
  store
) => {
  const externalurl = `/api/v1/Reconciliation/RollbackReconciliationRun`;
  const activityTag = "RollbackReconciliationRun";

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: {
      ReconciliationRunId: ReconciliationRunId,
    },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      store.loading = false;
      return data;
    })

    .catch((error) => {
      store.loading = false;
      return {};
    });
};

export const ResetReconciliationStatistics = async (
  StatisticTag,
  DataAcquisitionId,
  callback = () => {},
  store
) => {
  const externalurl = `/api/v1/DataAcquisition/ResetReconciliationStatistics`;
  const activityTag = "ResetReconciliationStatistics";

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: {
      StatisticTag: StatisticTag,
      StatisticCriteria: [
        {
          Key: "DataAcquisitionId",
          Value: DataAcquisitionId,
        },
      ],
    },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      store.loading = false;
      return data;
    })

    .catch((error) => {
      store.loading = false;
      return {};
    });
};

export const TriggerManualDataAcquisition = async (
  DataAcquisitionId,
  callback = () => {},
  store
) => {
  const externalurl = `/api/v1/DataAcquisition/TriggerManualDataAcquisition`;
  const activityTag = "TriggerManualDataAcquisition";

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: {
      DataAcquisitionId: DataAcquisitionId,
    },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      store.loading = false;
      return data;
    })

    .catch((error) => {
      store.loading = false;
      return {};
    });
};

export const DownloadTenantHierarchy = async (callback = () => {}) => {
  const externalurl = `/api/v1/Membership/DownloadTenantHierarchy`;
  const activityTag = "DownloadTenantHierarchy";

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: {
      TenantCode: "",
    },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      return data;
    })

    .catch((error) => {
      return {};
    });
};

export const RunManualReconciliation = async (
  ReconciliationStreamId,
  callback = () => {},
  store
) => {
  const externalurl = `/api/v1/Reconciliation/RunManualReconciliation`;
  const activityTag = "RunManualReconciliation";

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: {
      ReconciliationStreamId: ReconciliationStreamId,
    },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      store.loading = false;
      return data;
    })

    .catch((error) => {
      store.loading = false;
      return {};
    });
};

export const SkipNextDataAcquisitionInstance = async (
  DataAcquisitionId,
  callback = () => {},
  store
) => {
  const externalurl = `/api/v1/DataAcquisition/SkipNextDataAcquisitionInstance`;
  const activityTag = "SkipNextDataAcquisitionInstance";

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: {
      DataAcquisitionId: DataAcquisitionId,
    },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      store.loading = false;
      return data;
    })

    .catch((error) => {
      store.loading = false;
      return {};
    });
};

export const SkipNextReconciliationRun = async (
  ReconciliationStreamId,
  callback = () => {},
  store
) => {
  const externalurl = `/api/v1/Reconciliation/SkipNextReconciliationRun`;
  const activityTag = "SkipNextReconciliationRun";

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: {
      ReconciliationStreamId: ReconciliationStreamId,
    },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      store.loading = false;
      return data;
    })

    .catch((error) => {
      store.loading = false;
      return {};
    });
};

export const ExportReport = async (
  localStorageID,
  selectedFilters,
  callback = () => {},
  toastID,
  defaultMsg,
  reportTypeTag,
  FileRetrievalId = null
) => {
  const externalurl = `/api/v1/Reports/ExportReport`;
  const activityTag = "ExportReport";

  const extraBody = {
    ...(FileRetrievalId && { FileRetrievalId }),
    ReportTypeTag: reportTypeTag,
    FilteringProperties: [
      Object.keys(selectedFilters).map((filterKey) => {
        return {
          ColumnName: filterKey,
          Value: selectedFilters[filterKey],
          Operand: "EQ",
        };
      }),
    ],
  };

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody,
    // extraBody: {
    //   ...selectedFilters,
    // },
  });

  return apiRequest
    .then((data) => {
      // To simulate a file retrieval id uncomment the below line
      // data.File = "https://plutoapi.swittlelab.com/file/6728cd72f72a704268f06959?fn=2024-11-04_DA_FILE_DROP_26726_Raw_Data.csv&fz=0.002614975"
      if (!data.File && !data.FileRetrievalId) {
        const sfmData = GetSFMDisplay(data.SFM);
        toast.update(toastID, {
          render: `${sfmData.Message}`,
          type: "warning",
          isLoading: false,
          autoClose: 5000,
        });
        removeFromDownloadCenter({ id: localStorageID });
      } else if (data.File) {
        callback && callback(data);
        return data;
      } else if (data.FileRetrievalId) {
        // return pollForFile(
        //   localStorageID,
        //   data.FileRetrievalId,
        //   callback,
        //   store,
        //   activityTag,
        //   externalurl,
        //   filters,
        //   sort,
        //   MAX_POLLING_RETRIES,
        //   POLLING_DELAY,
        //   toastID,
        //   defaultMsg,
        //   extraBody
        // );
        return pollForFile(
          localStorageID,
          data.FileRetrievalId,
          callback,
          null,
          activityTag,
          externalurl,
          null,
          null,
          MAX_POLLING_RETRIES,
          POLLING_DELAY,
          toastID,
          defaultMsg,
          extraBody
        );
      }
    })

    .catch((error) => {
      return {};
    });
};

export const GetReconciliationFile = async (
  apiOrigin,
  FMSTag,
  Key,
  DataAcquisitionInstanceId,
  callback = () => {},
  store,
  languageCode = "en"
) => {
  const subPath = {
    Reconciliation: "Reconciliation",
    DataAcquisition: "DataAcquisition",
    ControlRoom: "ControlRoom",
  };
  const externalurl = `/api/v1/${subPath[apiOrigin]}/GetReconciliationFile`;
  const activityTag = "GetReconciliationFile";
  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: {
      SearchCriteria: [
        {
          Key: "FMSTag",
          Value: FMSTag,
        },
        {
          Key: Key,
          Value: DataAcquisitionInstanceId,
        },
      ],
    },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      store.loading = false;
      return data;
    })

    .catch((error) => {
      store.loading = false;
      return {};
    });
};

export const GetRevocableDataTotals = async (
  DataAcquisitionInstanceId,
  callback = () => {},
  store
) => {
  const externalurl = `/api/v1/DataAcquisition/GetRevocableDataTotals`;
  const activityTag = "GetRevocableDataTotals";

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: {
      DataAcquisitionInstanceId: DataAcquisitionInstanceId,
    },
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      store.loading = false;
      return data;
    })

    .catch((error) => {
      store.loading = false;
      return {};
    });
};

export const RevokeDataAcquisitionInstance = async (
  DataAcquisitionInstanceId,
  TotalPromotedRecords,
  TotalRevocableRecords,
  callback = () => {},
  store
) => {
  const externalurl = `/api/v1/DataAcquisition/RevokeDataAcquisitionInstance`;
  const activityTag = "RevokeDataAcquisitionInstance";

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody: {
      DataAcquisitionInstanceId: DataAcquisitionInstanceId,
      TotalPromotedRecords: TotalPromotedRecords,
      TotalRevocableRecords: TotalRevocableRecords,
    },
  });
  store.loading = true;

  return apiRequest
    .then((data) => {
      callback && callback(data);
      store.loading = false;
      return data;
    })

    .catch((error) => {
      store.loading = false;
      return {};
    });
};
const MAX_POLLING_RETRIES = 2;
const POLLING_DELAY = 20000;

export const ExportReconciliationRecords = async (
  localStorageID,
  filters,
  sort,
  sentActivityTag,
  callback = () => {},
  store,
  toastID,
  defaultMsg,
  FileRetrievalId = null
) => {
  const externalurl = `/api/v1/ControlRoom/ExportReconciliationRecords`;
  const activityTag = sentActivityTag;

  console.log("filters", filters);
  const extraBody = {
    ...(FileRetrievalId && { FileRetrievalId }),
    // ReportTypeTag: reportTypeTag,
    FilteringProperties: [
      filters[Object.keys(filters)[0]],
      // Object.keys(filters[0]).map((filterKey) => {
      //   return {
      //     ColumnName: filterKey,
      //     Value: filters[filterKey],
      //     Operand: "EQ",
      //   };
      // }),
    ],
  };

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    extraBody,
    // filter: {
    //   enabled: true,
    //   value: filters,
    // },
    // sort: {
    //   enabled: true,
    //   value: sort,
    // },
    // extraBody: {
    //   FileRetrievalId: null,
    // },
  });

  return apiRequest
    .then((data) => {
      if (data.File) {
        callback && callback(data);
        store.loading = false;
        return data;
      } else {
        // here we need to recall the api to get the file but instead of
        // extraBody: {
        //   FileRetrievalId: null
        // },
        // we will use the FileRetrievalId from the first call
        // if the second call returns the file then we will return the data
        // if not we need to call the api again
        return pollForFile(
          localStorageID,
          data.FileRetrievalId,
          callback,
          store,
          activityTag,
          externalurl,
          filters,
          sort,
          MAX_POLLING_RETRIES,
          POLLING_DELAY,
          toastID,
          defaultMsg,
          extraBody
        );
        //  return recallExportReconciliationRecords(data.FileRetrievalId, callback, store, activityTag, externalurl, filters, sort);
      }
      // return data;
    })

    .catch((error) => {
      store.loading = false;
      return {};
    });
};

const pollForFile = async (
  localStorageID,
  FileRetrievalId,
  callback = () => {},
  store,
  activityTag,
  externalurl,
  filters,
  sort,
  retries,
  delay,
  toastID,
  defaultMsg,
  extraBody = null
) => {
  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    filter: {
      enabled: extraBody ? false : true,
      value: filters,
    },
    sort: {
      enabled: extraBody ? false : true,
      value: sort,
    },
    extraBody: {
      FileRetrievalId: FileRetrievalId,
      ...extraBody,
    },
  });

  return apiRequest
    .then((data) => {
      if (data.File) {
        callback && callback(data);
        store.loading = false;
        return data;
      } else if (data.FileRetrievalId && retries > 0) {
        // toast.update(toastID, { render: `${defaultMsg} (${retries }/${MAX_POLLING_RETRIES})`});
        const sfmData = GetSFMDisplay(data.SFM);
        toast.update(toastID, {
          render: `${sfmData.Message} (${MAX_POLLING_RETRIES + 2 - retries}/${
            MAX_POLLING_RETRIES + 2
          })`,
          type: "warning",
          autoClose: 5000,
        });
        if (data.FileRetrievalId) {
          updateDownloadItem({
            id: localStorageID,
            newProps: {
              fileRetrievalId: data.FileRetrievalId,
            },
          });
        }
        return new Promise((resolve) => {
          setTimeout(() => {
            // console.log('toastDI', toastID)
            resolve(
              pollForFile(
                localStorageID,
                // FileRetrievalId,
                data.FileRetrievalId,
                callback,
                store,
                activityTag,
                externalurl,
                filters,
                sort,
                retries - 1,
                delay,
                toastID,
                defaultMsg,
                extraBody
              )
            );
          }, delay);
        });
      } else {
        const sfmData = GetSFMDisplay(data.SFM);
        toast.update(toastID, {
          render: `${sfmData.Message}`,
          type: "warning",
          isLoading: false,
          autoClose: 5000,
        });
        return data;
      }
    })
    .catch((error) => {
      store.loading = false;
      return {};
    });
};
// const recallExportReconciliationRecords = async (
//   FileRetrievalId,
//   callback = () => {},
//   store,
//   activityTag,
//   externalurl,
//   filters,
//   sort
// ) => {
//   const apiRequest = apiHelper({
//     externalurl,
//     activityTag,
//     filter: {
//       enabled: true,
//       value: filters,
//     },
//     sort: {
//       enabled: true,
//       value: sort,
//     },
//     extraBody: {
//       FileRetrievalId: FileRetrievalId
//     },
//   });

//   return apiRequest
//     .then((data) => {
//       callback && callback(data);
//       store.loading = false;
//       if (data.FileRetrievalId) {
//         return data;
//       } else{
//         return recallExportReconciliationRecords(data.FileRetrievalId, callback, store, activityTag, externalurl, filters, sort);
//       }
//     })
//     .catch((error) => {
//       store.loading = false;
//       return {};
//     });
// };
export const DropDataAcquisitionFile = async (
  DataAcquisitionId,
  uploadedFile,
  callback = () => {},
  store
) => {
  const externalurl = `/api/v1/DataAcquisition/DropDataAcquisitionFile`;
  const activityTag = "DropDataAcquisitionFile";

  const formData = new FormData();
  formData.append("DataAcquisitionId", DataAcquisitionId);
  formData.append("File", uploadedFile);

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    onlyBody: formData,
    headerContentType: null,
    serializeBody: false,
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      store.loading = false;
      return data;
    })
    .catch((error) => {
      store.loading = false;
      return {};
    });
};

export const SaveTenantHierarchyFile = async (
  uploadedFile,
  callback = () => {}
) => {
  const externalurl = `/api/v1/Membership/SaveTenantHierarchyFile`;
  const activityTag = "SaveTenantHierarchyFile";

  const TenantCode = "";

  const formData = new FormData();
  formData.append("File", uploadedFile);

  const apiRequest = apiHelper({
    externalurl,
    activityTag,
    onlyBody: formData,
    headerContentType: null,
    serializeBody: false,
  });

  return apiRequest
    .then((data) => {
      callback && callback(data);
      return data;
    })
    .catch((error) => {
      return {};
    });
};

export const isSfmSuccess = (sfmDisplay) => {
  return (
    sfmDisplay !== null &&
    sfmDisplay !== undefined &&
    MESSAGE_TYPE[sfmDisplay.Type] === MESSAGE_TYPE[1]
  );
};

export const transformData = (data, onlyChildren = false) => {
  const children = data
    ? data.map((item) => {
        return {
          displayName: item.Label,
          value: item.Value,
          selectable: true,
        };
      })
    : [];
  return onlyChildren
    ? children
    : [
        {
          displayName: "All",
          value: null,
          selectable: false,
          children: children,
        },
      ];
};

export const GetSFMDisplay = (sfm) => {
  let SfmDisplay = {};
  if (sfm != null) {
    var majorConstant = sfm?.Major?.Constant;
    var shortDescription =
      sfm?.Minor?.[0]?.MinorRetCodeDetails?.[0]?.ShortDescription;
    var longDescription =
      sfm?.Minor?.[0]?.MinorRetCodeDetails?.[0]?.LongDescription;
    SfmDisplay = {
      DetailedMessage: longDescription,
      Message: shortDescription,
      SfmTag: sfm?.Constant,
      Type: MessageTypes.INFO,
    };

    if (majorConstant === MAJ_SUCCESS) {
      SfmDisplay.ShowMessage = false;
      SfmDisplay.Type = MessageTypes.SUCCESS;
    } else if (majorConstant === MAJ_SUCCESS_INFO) {
      SfmDisplay.Type = MessageTypes.SUCCESS;
      SfmDisplay.ShowMessage = true;
    } else if (majorConstant === MAJ_SUCCESS_WARN) {
      SfmDisplay.Type = MessageTypes.WARNING;
      SfmDisplay.ShowMessage = true;
    } else if (
      majorConstant?.indexOf(MAJ_ERR) > -1 ||
      majorConstant?.indexOf(MAJ_VAL_ERR) > -1
    ) {
      SfmDisplay.Type = MessageTypes.ERROR;
      SfmDisplay.ShowMessage = true;
    }
  }
  return SfmDisplay;

  // if (showMessage.HasValue)
  // {
  //     sfmDisplay.ShowMessage = showMessage.Value;
  // }
};

export const directions = (translate) => {
  return {
    asc: {
      display: translate("shared.directions.ascending"),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="size-4 text-gray-700"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
          />
        </svg>
      ),
    },
    desc: {
      display: translate("shared.directions.descending"),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="size-4 text-gray-700"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
          />
        </svg>
      ),
    },
  };
};
export const connectPromotedRecords = (promotedRecords, matchingRecords) => {
  return matchingRecords?.map((matchingRecord) => {
    matchingRecord.SourceRecords = matchingRecord.SourcePromotedRecordIds?.map(
      (record) => {
        return {
          ...matchingRecord,
          ...promotedRecords.find(
            (promotedRecord) => promotedRecord.PromotedRecordId === record
          ),
        };
      }
    );
    matchingRecord.DestinationRecords =
      matchingRecord.DestinationPromotedRecordIds?.map((record) => {
        return {
          ...matchingRecord,
          ...promotedRecords.find(
            (promotedRecord) => promotedRecord.PromotedRecordId === record
          ),
        };
      });
    return matchingRecord;
  });
};

export const RoundToTwoDecimals = (number) => {
  const FactorialNumber = 100;
  if (Number.isInteger(number)) {
    return number;
  }
  return Math.ceil(number * FactorialNumber) / FactorialNumber;
};

export const FormatWithCommas = (number) => {
  if (isNaN(number)) {
    return "";
  }
  return number?.toLocaleString();
};

export const helperTitle = (title) => {
  return (
    <span className="font-semibold">
      {title.substring(0, 1)}
      <span className="font-normal">{title.substring(1, title.length)}</span>
    </span>
  );
};

export const resolveStatusColumn = (
  statusName = "",
  statusTag = "",
  pingOn = ["INPROGRESS"],
  customColors = null,
  externelInProgess = ""
) => {
  const statusColors = {
    INPROGRESS: "bg-green-400",
    ACTIVE: "bg-green-400",
    COMPLETED: "bg-green-600",
    COMPLETED_WARNING: "bg-orange-400",
    PARTIALLY_REVOKED: "bg-orange-700",
    REVOKED: "bg-red-900",
    FAILED: "bg-red-600",
    DISABLED: "bg-red-400",
    ABORTED: "bg-red-900",
    ROLLED_BACK: "bg-yellow-400",
    ROLLING_BACK: "bg-orange-600",
  };
  const colorsArray = customColors || statusColors;
  const shouldAnimate =
    pingOn.includes(statusTag) ||
    pingOn.includes(statusTag.toUpperCase()) ||
    pingOn.includes(externelInProgess);
  const colorUsed =
    colorsArray[statusTag] || colorsArray[statusTag.toUpperCase()];
  return (
    <div className="flex flex-col gap-1">
      <span
        title={statusName}
        className="rounded-full flex items-end justify-center gap-1 w-fit font-normal text-sm border border-r-10 border-transparent"
      >
        <span className="relative flex h-3 w-3">
          <span
            className={clsx(
              shouldAnimate
                ? "animate-[ping_1500ms_ease-out_900ms_infinite] opacity-100"
                : "opacity-20",
              "absolute inline-flex h-full w-full rounded-full",
              colorUsed,
              "opacity-75"
            )}
          ></span>
          <span
            className={clsx(
              shouldAnimate ? "opacity-100" : "opacity-0",
              "relative inline-flex rounded-full h-3 w-3",
              colorUsed
            )}
          ></span>
        </span>
      </span>
    </div>
  );
};

export const resolveAgingTierColumn = (
  agingTier = "",
  agingTierTag = "",
  customColors = null
) => {
  const statusColors = {
    EXCEPTION_LOW_AGING_THRESHOLD_IN_HOURS: "text-orange-400",
    EXCEPTION_MODERATE_AGING_THRESHOLD_IN_HOURS: "tex-orange-900",
    EXCEPTION_CRITICAL_AGING_THRESHOLD_IN_HOURS: "text-red-400",
  };
  const colorsArray = customColors || statusColors;
  const colorUsed =
    colorsArray[agingTierTag] || colorsArray[agingTierTag?.toUpperCase()];
  return (
    <span
      className={clsx("relative inline-flex rounded-full h-3 w-3", colorUsed)}
    >
      {agingTier.AgingDays}
    </span>
  );
};

export const resolveMismatchColumn = (
  mismatches,
  customColors = null,
  pingOn = ["INPROGRESS"]
) => {
  const statusColors = {
    CRITICAL: "bg-red-400",
    HIGH: "bg-red-400",
    MEDIUM: "bg-orange-400",
    LOW: "bg-yellow-400",
  };
  const textColors = {
    CRITICAL: "text-red-400",
    HIGH: "text-red-400",
    MEDIUM: "text-orange-400",
    LOW: "text-yellow-400",
  };
  const colorsArray = customColors || statusColors;

  return (
    <div className="flex flex-col gap-1">
      {mismatches.map((mismatch, index) => {
        const { MismatchSeverityTag, MismatchTag, MismatchName } = mismatch;
        const shouldAnimate =
          pingOn.includes(MismatchSeverityTag) ||
          pingOn.includes(MismatchSeverityTag?.toUpperCase());
        const circleBgColor =
          colorsArray[MismatchSeverityTag] ||
          colorsArray[MismatchSeverityTag?.toUpperCase()];
        const textColor =
          textColors[MismatchSeverityTag] ||
          textColors[MismatchSeverityTag?.toUpperCase()];
        return (
          <span
            key={MismatchTag + index + MismatchSeverityTag}
            className={`rounded-full flex items-center justify-between gap-2 w-fit font-normal text-sm border border-r-10 border-transparent `}
          >
            {circleBgColor && (
              <span className="relative flex h-3 w-3">
                <span
                  className={clsx(
                    shouldAnimate &&
                      "animate-[ping_1500ms_ease-out_900ms_infinite]",
                    `absolute inline-flex h-full w-full rounded-full opacity-75`,
                    circleBgColor
                  )}
                ></span>
                <span
                  className={`relative inline-flex rounded-full h-3 w-3 ${circleBgColor}`}
                ></span>
              </span>
            )}
            <span className={`${textColor}`}>{MismatchName}</span>
          </span>
        );
      })}
    </div>
  );
};

export const noDataHelper = (defaultMsg = "No data") => {
  return (
    <div className="text-center w-full flex flex-col items-center gap-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3"
        />
      </svg>
      <span>{defaultMsg}</span>
    </div>
  );
};

export const addToDownloadCenter = ({
  href,
  done = true,
  fileRetrievalId,
  fileType = "xlxs",
  size = "-",
  creationDate = new Date().toLocaleDateString(),
  creationTime = new Date().toLocaleTimeString(),
  creationDateTime = new Date().toISOString(),
  notes = "",
  title = "",
  activityTag = "",
  apiEndpoint = "",
  apiParams = {},
  toastID = "",
  reportType = "",
}) => {
  const download_center_key = "DOWNLOAD_CENTER";
  // const now = new Date().now();
  const random = Math.floor(Math.random() * 1000);

  const downloadCenterItems = localStorage.getItem(download_center_key)
    ? JSON.parse(localStorage.getItem(download_center_key))
    : [];

  const customID = `${random}_${downloadCenterItems.length + 1}`;
  const newItem = {
    href: href,
    done: done,
    fileRetrievalId: fileRetrievalId,
    fileType: fileType,
    fileSize: size,
    creationDate: creationDate,
    creationTime: creationTime,
    creationDateTime: creationDateTime,
    notes: notes,
    // title: title || `${creationDate}${activityTag}_Matching_Results`,
    // title: title || `${href.split("/").pop()}`,
    title: title,
    activityTag: activityTag,
    id: customID,
    apiEndpoint: apiEndpoint,
    apiParams: apiParams,
    toastID: toastID,
    reportType: reportType,
  };

  downloadCenterItems.push(newItem);
  localStorage.setItem(
    download_center_key,
    JSON.stringify(downloadCenterItems)
  );
  return customID;
};

export const extractFileName = (href) => {
  // url format is assumed to be like this
  // https://plutoapi.swittlelab.com/file/670d17b3ab6e19755d0fbb2d?fn=2024-10-14_Exception_Records%20%281%29.xlsx
  const url = new URL(href);
  const fileName = url.searchParams.get("fn") || href.split("/").pop() || "";
  return fileName;
};

export const extractFileSize = (href) => {
  // url format is assumed to be like this
  // https://plutoapi.swittlelab.com/file/670d17b3ab6e19755d0fbb2d?fn=2024-10-14_Exception_Records%20%281%29.xlsx
  const url = new URL(href);
  const fileSize = url.searchParams.get("fz") || "";
  console.log("filesizeeee:", fileSize);
  return fileSize;
};
export const removeFromDownloadCenter = ({ id }) => {
  const download_center_key = "DOWNLOAD_CENTER";
  // const now = new Date().now();

  const downloadCenterItems = localStorage.getItem(download_center_key)
    ? JSON.parse(localStorage.getItem(download_center_key))
    : [];

  const newItems = downloadCenterItems.filter((item) => item.id !== id);
  localStorage.setItem(download_center_key, JSON.stringify(newItems));
};
export const clearDownloadCenter = () => {
  const download_center_key = "DOWNLOAD_CENTER";

  localStorage.setItem(download_center_key, JSON.stringify([]));
};

export const updateDownloadItem = ({ id, newProps }) => {
  const download_center_key = "DOWNLOAD_CENTER";
  // const now = new Date().now();

  const downloadCenterItems = localStorage.getItem(download_center_key)
    ? JSON.parse(localStorage.getItem(download_center_key))
    : [];

  const newItems = downloadCenterItems.map((item) => {
    if (item.id === id) {
      return {
        ...item,
        ...newProps,
      };
    } else {
      return item;
    }
  });

  localStorage.setItem(download_center_key, JSON.stringify(newItems));
};

export const humanize = (str = "") => {
  return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
    return str.toUpperCase();
  });
};

export const parameterize = (str = "") => {
  return str
    .replace(/[^a-zA-Z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .toLowerCase();
};
