import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import clsx from "clsx";

const CustomFileUploader = ({ key, classes, handleChange, name, types }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const generateContent = (errorMessage = "", errorClassName = "") => (
    <div
      className={clsx(
        `flex items-center justify-between cursor-pointer gap-1 border-dotted border-2 border-primary-300 rounded-md p-3 px-4`,
        errorMessage && "text-red-600"
      )}
    >
      <div className="flex items-center gap-1">
        <div className="flex items-center text-sm">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.33317 6.66667H22.6665V16H25.3332V6.66667C25.3332 5.196 24.1372 4 22.6665 4H5.33317C3.8625 4 2.6665 5.196 2.6665 6.66667V22.6667C2.6665 24.1373 3.8625 25.3333 5.33317 25.3333H15.9998V22.6667H5.33317V6.66667Z"
              fill="#0658C2"
            ></path>
            <path
              d="M10.6665 14.6667L6.6665 20H21.3332L15.9998 12L11.9998 17.3333L10.6665 14.6667Z"
              fill="#0658C2"
            ></path>
            <path
              d="M25.3332 18.6667H22.6665V22.6667H18.6665V25.3333H22.6665V29.3333H25.3332V25.3333H29.3332V22.6667H25.3332V18.6667Z"
              fill="#0658C2"
            ></path>
          </svg>
          {errorMessage ? (
            <div className={`text-xs ${errorClassName}`}>{errorMessage}</div>
          ) : (
            <p className="text-primary-500">Drag and drop your files here</p>
          )}
        </div>
        {!errorMessage && (
          <p className="text-gray-500 text-xs">or click to browse</p>
        )}
      </div>
      <div className="text-xs">{types.join(", ")}</div>
    </div>
  );

  useEffect(() => {
    setCurrentState(generateContent(errorMessage));
  }, [errorMessage]);

  const [currentState, setCurrentState] = useState(generateContent);
  return (
    <FileUploader
      key={key}
      classes={classes}
      handleChange={handleChange}
      name={name}
      types={types}
      onTypeError={(err) => {
        setErrorMessage("Wrong File Type. Please choose another file");
      }}
      onSizeError={(err) => {
        setErrorMessage("File Size too Big. Please choose another file");
      }}
    >
      {currentState}
    </FileUploader>
  );
};

export default CustomFileUploader;
