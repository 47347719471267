import React, { useEffect, useState } from "react";
import {
  getTenantHierarchy,
  SaveTenantHierarchyFile,
  DownloadTenantHierarchy,
  addToDownloadCenter,
  extractFileName,
  extractFileSize,
  updateDownloadItem,
} from "@helpers/helpers";
import CustomTable from "@components/CustomTable";
import Loader from "@components/LoaderComponent/loader";
import ButtonComponent from "@components/ButtonComponent";
import { FileUploader } from "react-drag-drop-files";
import TitleComponent from "@components/TitleComponent";
import Confirmationpopup from "@components/ConfirmationPopup";
import SfmModalStore from "@stores/SfmModalStore";
import { GetSFMDisplay } from "@helpers/helpers";
import PopupComponent from "@components/PopupComponent";
import { useTranslation } from "react-i18next";
import TreeComponent from "@components/TreeComponent";
import { toast } from "react-toastify";
import DownloadModalStore from "@stores/DownloadModalStore";
import clsx from "clsx";
import Highlighter from "react-highlight-words";

import HierarchyComponent from "@components/HierarchyComponent";

import CustomFileUploader from "@components/CustomFileUploader";

const TenantDetails = ({ resetComponent }) => {
  const { t: translate, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [tenantOrgs, setTenantOrgs] = useState([]);
  const [openFileDropModal, setOpenFileDropModal] = useState(false);
  const [refreshFileDrop, setRefreshFileDrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmation] = useState(null);
  const [transformData, setTransformedData] = useState({});
  const [treeTransformData, setTreeTransformedData] = useState({});
  const [file, setFile] = useState({});
  const [showTree, setShowTree] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [infoPopupOpen, setInfoPopupOpen] = useState(false);
  const [currentBranch, setCurrentBranch] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const fileTypes = ["xlsx", "xls"];

  const handleFileDrop = (droppedFile) => {
    setIsLoading(true);
    SaveTenantHierarchyFile(droppedFile, (data) => {
      const sfmData = GetSFMDisplay(data.SFM);
      SfmModalStore.open(sfmData, () => {
        window.location.href = "/tenant-hierarchy";
      });
    });
  };

  const handleChange = (file) => {
    setFile(file);
    setOpenConfirmation({
      actionTypeTag: "FILE_DROP",
      name: translate("tenant-details.actions.FILE_DROP"),
      actionFN: () => handleFileDrop(file),
      cancelAction: () => setRefreshFileDrop(!refreshFileDrop),
      confirmationMessage: {
        text: translate("tenant-details.file-upload"),
        fileName: file.name,
      },
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    // Use the extracted ID as needed, for example:
    getTenantHierarchy((tenantDetails) => {
      const tenantOrgsDetails = tenantDetails.TenantOrgs;
      setTenantOrgs(tenantOrgsDetails);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (tenantOrgs.length === 0) return;
    const parentNode = tenantOrgs.find((record) => {
      return record.Level === 0;
    });

    const firstChilds = tenantOrgs
      .filter((record) => {
        return record.Level !== 0;
      })
      .filter((record) => record.ParentOrgId === parentNode.OrgId);

    const externalCodeTag = "ORG_EXTERNAL_CODE";
    const separator = " | ";

    const transformedData = {
      id: parentNode.OrgId.toString(),
      data: {
        imageURL: "",
        fontSize: "20px",
        name: parentNode.OrgDetails.filter((detail) => {
          return detail.LanguageCode === i18n.language;
        })[0].ShortName,
        tooltip: "tooltip level 0",
      },
      options: {
        nodeBGColor: "#03256C",
        nodeBGColorHover: "#03256C",
      },
      children: firstChilds.map((org) => ({
        id: org.OrgId.toString(),
        data: {
          imageURL: "",
          fontSize: "18px",
          name:
            org.OrgDetails.find((detail) => detail.LanguageCode === "en")
              .ShortName +
            "<br>" +
            `(${org.OrgAttributes.filter((attribute) => {
              return attribute.AttributeTypeTag === externalCodeTag;
            })
              .map((attribute, index) => {
                return (
                  attribute.AttributeValue +
                  (index !== org.OrgAttributes.length - 1 ? separator : "")
                );
              })
              .join("")})`,
          tooltip: "tooltip level 1",
        },
        options: {
          nodeBGColor: "#2541B2",
          nodeBGColorHover: "#2541B2",
        },
        children: [
          ...tenantOrgs
            .filter((record) => record.ParentOrgId === org.OrgId)
            .map((branch, index) => {
              const currentChild = tenantOrgs.filter(
                (record) => record.ParentOrgId === org.OrgId
              )[0];
              return {
                id: branch.OrgId.toString(),
                data: {
                  nodeBGColor: "#1768AC",
                  nodeBGColorHover: "#1768AC",
                  fontSize: "16px",
                  imageURL: "",
                  name:
                    branch.OrgDetails.find(
                      (detail) => detail.LanguageCode === "en"
                    ).ShortName +
                    "<br>" +
                    `(${branch.OrgAttributes.filter((attribute) => {
                      return attribute.AttributeTypeTag === externalCodeTag;
                    })
                      .map((attribute, index) => {
                        return (
                          attribute.AttributeValue +
                          (index !== branch.OrgAttributes.length - 1
                            ? separator
                            : "")
                        );
                      })
                      .join("")})`,
                  tooltip: "tooltip level 2",
                },
                options: {
                  nodeBGColor: "#1768AC",
                  nodeBGColorHover: "#1768AC",
                },
                children: currentChild.OrgDevices.map((terminal) => ({
                  id: terminal.DeviceId.toString(),
                  data: {
                    name: terminal.DeviceName,
                    fontSize: "13px",
                    nodeBGColor: "#06BEE1",
                    nodeBGColorHover: "#06BEE1",
                    tooltip: "tooltip level 3",
                  },
                  options: {
                    nodeBGColor: "#06BEE1",
                    nodeBGColorHover: "#06BEE1",
                  },
                })),
              };
            }),
        ],
      })),
    };
    const treeTransformedData = {
      id: parentNode.OrgId.toString(),
      data: {
        imageURL: "",
        fontSize: "20px",
        name: parentNode.OrgDetails.filter((detail) => {
          return detail.LanguageCode === i18n.language;
        })[0].ShortName,
        tooltip: "tooltip level 0",
      },
      options: {
        nodeBGColor: "#03256C",
        nodeBGColorHover: "#03256C",
      },
      children: firstChilds.map((org) => ({
        id: org.OrgId.toString(),
        data: {
          imageURL: "",
          fontSize: "18px",
          name:
            org.OrgDetails.find((detail) => detail.LanguageCode === "en")
              .ShortName +
            "<br>" +
            `(${org.OrgAttributes.filter((attribute) => {
              return attribute.AttributeTypeTag === externalCodeTag;
            })
              .map((attribute, index) => {
                return (
                  attribute.AttributeValue +
                  (index !== org.OrgAttributes.length - 1 ? separator : "")
                );
              })
              .join("")})`,
          tooltip: "tooltip level 1",
        },
        options: {
          nodeBGColor: "#2541B2",
          nodeBGColorHover: "#2541B2",
        },
        children: [
          ...tenantOrgs
            .filter((record) => record.ParentOrgId === org.OrgId)
            .map((branch, index) => {
              const currentChild = tenantOrgs.filter(
                (record) => record.ParentOrgId === org.OrgId
              )[0];
              const terminals = currentChild.OrgDevices.map((terminal) => ({
                id: terminal.DeviceId.toString(),
                data: {
                  name: terminal.DeviceName,
                  fontSize: "13px",
                  nodeBGColor: "#06BEE1",
                  nodeBGColorHover: "#06BEE1",
                  tooltip: "tooltip level 3",
                },
                options: {
                  nodeBGColor: "#06BEE1",
                  nodeBGColorHover: "#06BEE1",
                },
              }));

              const distributeTerminals = (terminals) => {
                const distributed = [];
                let currentParent = null;

                terminals.forEach((terminal, index) => {
                  if (index % 5 === 0) {
                    currentParent = {
                      id: terminal.id,
                      data: terminal.data,
                      options: terminal.options,
                      children: [],
                    };
                    distributed.push(currentParent);
                  }
                  let currentChild = currentParent;
                  while (currentChild.children?.length > 0) {
                    currentChild = currentChild.children[0];
                  }
                  if (!currentChild.children) {
                    currentChild.children = [];
                  }
                  currentChild.children?.push(terminal);
                });

                return distributed;
              };

              return {
                id: branch.OrgId.toString(),
                data: {
                  nodeBGColor: "#1768AC",
                  nodeBGColorHover: "#1768AC",
                  fontSize: "16px",
                  imageURL: "",
                  name:
                    branch.OrgDetails.find(
                      (detail) => detail.LanguageCode === "en"
                    ).ShortName +
                    "<br>" +
                    `(${branch.OrgAttributes.filter((attribute) => {
                      return attribute.AttributeTypeTag === externalCodeTag;
                    })
                      .map((attribute, index) => {
                        return (
                          attribute.AttributeValue +
                          (index !== branch.OrgAttributes.length - 1
                            ? separator
                            : "")
                        );
                      })
                      .join("")})`,
                  tooltip: "tooltip level 2",
                },
                options: {
                  nodeBGColor: "#1768AC",
                  nodeBGColorHover: "#1768AC",
                },
                children: distributeTerminals(terminals),
              };
            }),
        ],
      })),
    };
    // console.log(transformData)

    setTransformedData(transformedData);
    setTreeTransformedData(treeTransformedData);
  }, [tenantOrgs]);

  const pinningInitialState = ["pin"];

  const columns = [
    {
      accessorKey: "OrgDetails",
      size: 350,
      sortable: true,
      canPin: false,
      canFilter: false,
      hideHeader: true,
      cell: ({ row }) => {
        return (
          <div className="flex flex-col gap-1 w-full items-start justify-start">
            {row.original.OrgDetails.filter((detail) => {
              return detail.LanguageCode === i18n.language;
            }).map((detail, index) => (
              <span
                key={index}
                className={`rounded-full text-xl items-center justify-between gap-2 w-fit font-normal border border-r-10 border-transparent `}
              >
                <span title={detail.LongName}>{detail.ShortName}</span>
              </span>
            ))}
          </div>
        );
      },
    },
  ];

  const childrenColumns = [
    {
      accessorKey: "OrgDetails",
      header: translate("tenant-details.children-columns.district"),
      size: 200,
      hideHeader: true,
      cell: ({ row }) => {
        return (
          <div className="flex flex-col gap-1">
            {row.original.OrgDetails.filter((detail) => {
              return detail.LanguageCode === i18n.language;
            }).map((detail, index) => (
              <span
                key={index}
                className={`rounded-full items-center justify-between gap-2 w-fit font-normal text-lg border border-r-10 border-transparent `}
              >
                <span title={detail.LongName}>{detail.ShortName}</span>
              </span>
            ))}
          </div>
        );
      },
    },
    {
      accessorKey: "codes",
      header: translate("tenant-details.codes"),
      size: 300,
      hideHeader: true,
      cell: ({ row }) => {
        const externalCodeTag = "ORG_EXTERNAL_CODE";
        const separator = "|";
        const filteredAttributes = row.original.OrgAttributes.filter(
          (attribute) => {
            return attribute.AttributeTypeTag === externalCodeTag;
          }
        );
        return (
          <div className="flex gap-1 w-full justify-start">
            {filteredAttributes.map((singleAttribute, index) => (
              <span
                key={index}
                className={`rounded-full items-center justify-between gap-2 w-fit font-normal text-lg border border-r-10 border-transparent `}
              >
                <span>
                  {singleAttribute.AttributeValue}{" "}
                  {index !== filteredAttributes.length - 1 && separator}
                </span>
              </span>
            ))}
          </div>
        );
      },
    },
  ];

  const terminalColumns = [
    {
      accessorKey: "branch",
      header: translate("tenant-details.terminal-columns.branch"),
      size: 250,
      cell: ({ row }) => {
        return (
          <div className="flex flex-col gap-1">
            {row.original.OrgDetails.filter((detail) => {
              return detail.LanguageCode === i18n.language;
            }).map((detail, index) => (
              <span
                key={index}
                className={`rounded-full items-center justify-between gap-2 w-fit font-normal text-lg border border-r-10 border-transparent `}
              >
                <span title={detail.LongName}>{detail.ShortName}</span>
              </span>
            ))}
          </div>
          // <div className="flex flex-col gap-1">{row.original.OrgDevices.StatusTag}</div>
        );
      },
    },
    {
      accessorKey: "address",
      header: translate("tenant-details.terminal-columns.address"),
      size: 250,
      cell: ({ row }) => {
        return (
          <div className="flex flex-col gap-4">
            {row.original.OrgAddresses.map((adress, index) => {
              return (
                <div key={index}>
                  {adress.AddressDetails.filter((detail) => {
                    return detail.LanguageCode === i18n.language;
                  }).map((detail, index) => (
                    <span
                      key={index}
                      className={`rounded-full items-center justify-between gap-2 w-fit font-normal text-sm border border-r-10 border-transparent `}
                    >
                      <span title={detail.City}>
                        {detail.City}, {detail.District}, {detail.Sector},{" "}
                        {detail.StreetAddress}
                      </span>
                    </span>
                  ))}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      accessorKey: "contacts",
      header: translate("tenant-details.terminal-columns.contacts"),
      size: 250,
      cell: ({ row }) => {
        return (
          <div className="flex flex-col gap-1">
            {row.original.OrgContacts.map((device, index) => {
              return (
                <div className="flex justify-between" key={index}>
                  <span>{device.DeviceName}</span>
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      accessorKey: "terminals",
      header: translate("tenant-details.terminal-columns.terminals"),
      size: 250,
      cell: ({ row }) => {
        return (
          <div className="flex flex-col gap-1">
            {row.original.OrgDevices.map((device, index) => {
              return (
                <div className="flex justify-between" key={index}>
                  <span>{device.DeviceName}</span>
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];

  const noData = () => {
    return (
      <div className="text-center w-full flex flex-col items-center gap-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3"
          />
        </svg>
        <span>{translate("shared.no-data")}</span>
      </div>
    );
  };

  const renderChildTable = (children, isExpandable = true) => {
    const dataToSend =
      isExpandable &&
      children.map((child, index) => {
        return {
          ...child,
          customID: index,
        };
      });
    return (
      <CustomTable
        language={i18n.language}
        externalData={isExpandable ? dataToSend : [children]}
        columnsWithoutPin={isExpandable ? childrenColumns : terminalColumns}
        loading={false}
        {...(isExpandable
          ? { title: translate("tenant-details.areas") }
          : { title: translate("tenant-details.outlets") })}
        pinningInitialState={isExpandable ? pinningInitialState : []}
        setParentSelectedValues={() => {}}
        prefix={
          isExpandable ? "children-tenant-details" : "terminal-tenant-details"
        }
        pinnedRowHeight={62}
        // maxTableHeight={400}
        showFilter={false}
        minimalTable={true}
        hideActionColumn={true}
        noData={noData}
        isExpandable={false}
        allowRowPinning={false}
        pinCondition={(row) => {
          return isExpandable;
        }}
        {...(isExpandable && {
          expandedViewRow: (row) => {
            const childrenInner = tenantOrgs.filter(
              (record) => record.ParentOrgId === row.original.OrgId
            )[0];
            return (
              <div className="">{renderChildTable(childrenInner, false)}</div>
            );
          },
        })}
        {...(isExpandable && {
          selectableCondition: (row, isHeader) => {
            return false;
          },
        })}
        autoFillData={false}
        rowCustomID={(row) => {
          return row.PromotedRecordId;
        }}
        customSubRows={(row) => {
          return row.DestinationRecords;
        }}
        allowExpansion={false}
        isExpandedByDefault={isExpandable}
      />
    );
  };

  const handleToggle = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setShowTree(!showTree);
      setIsTransitioning(false);
    }, 300); // Match the duration of the CSS transition
  };

  const renderTables = () => {
    let matchingRecords = tenantOrgs
      .filter((record) => {
        return record.Level === 0;
      })
      .map((record, index) => {
        return {
          ...record,
          customID: index,
        };
      });
    let childrenRecords = tenantOrgs.filter((record) => {
      return record.Level !== 0;
    });

    return (
      <>
        <div className="p-5 w-full flex flex-col gap-5">
          <div className="flex justify-between items-center w-full m-auto">
            <span>
              {translate("tenant-details.columns.TenantLastUpdateDate")}:{" "}
              {formatDate(matchingRecords[0]?.LastUpdateDate)}
            </span>
            <div className="button-wrapper flex gap-2 items-center">
              <ButtonComponent
                className="text-sm min-w-max h-fit  p-2 rounded-lg px-4 cursor-pointer"
                withoutBorder={false}
                id="download-details"
                color="blue"
                onClick={() => {
                  const toastId = toast.loading(
                    translate("tenant-details.processing-download")
                  );
                  const newPendingID = addToDownloadCenter({
                    href: "-",
                    activityTag: "DownloadTenantHierarchy",
                    done: false,
                  });
                  DownloadTenantHierarchy((data) => {
                    if (data.File) {
                      const fileName = extractFileName(data.File);
                      const fileSize = extractFileSize(data.File);
                      updateDownloadItem({
                        id: newPendingID,
                        newProps: {
                          href: data.File,
                          title: fileName,
                          fileSize: fileSize,
                          done: true,
                        },
                      });
                      toast.update(toastId, {
                        render: translate("tenant-details.download-ready"),
                        type: "success",
                        isLoading: false,
                        autoClose: 3000,
                      });
                      DownloadModalStore.open(500);
                      DownloadModalStore.highlight(500, newPendingID);
                      window.open(data.File, "_blank");
                    } else {
                      const sfmData = GetSFMDisplay(data.SFM);
                      toast.update(toastId, {
                        render: `${sfmData.Message}`,
                        type: "warning",
                        isLoading: false,
                        autoClose: 5000,
                      });
                    }
                  });
                }}
                ref={null}
                icon={null}
                text={translate("tenant-details.download")}
              />
              <ButtonComponent
                className="text-sm min-w-max h-fit  p-2 rounded-lg px-4 cursor-pointer"
                withoutBorder={false}
                id="upload-details"
                color="green"
                onClick={() => setOpenFileDropModal(true)}
                ref={null}
                icon={null}
                text={translate("tenant-details.upload")}
              />
            </div>
          </div>

          <div className="text-lg font-semibold flex justify-between items-center">
            {translate("tenant-details.title")}
            <button className="flex items-center py-3" onClick={handleToggle}>
              <span>Show Diagram</span>{" "}
              <input
                type="checkbox"
                className="hidden"
                id="switch"
                {...(showTree && {
                  checked: true,
                })}
                tabIndex={-1}
              />
              <label
                htmlFor="switch"
                className={clsx(
                  `flex items-center ml-4 cursor-pointer w-10 h-5 rounded-full p-1 transition duration-300 ease-in-out pointer-events-none`,
                  showTree ? "bg-[#c6cdff]" : "bg-gray-400"
                )}
                tabIndex={-1}
              >
                <span
                  className={clsx(
                    `block w-4 h-4 rounded-full bg-white shadow-md transform duration-300 ease-in-out`,
                    showTree ? "translate-x-4" : ""
                  )}
                />
              </label>
            </button>
          </div>
          <div className="w-full">
            <div
              className={`transition-opacity duration-300 transform ${
                isTransitioning ? "opacity-0 scale-90" : "opacity-100 scale-100"
              }`}
            >
              {showTree ? (
                <div className="flex justify-center mt-5 w-full">
                  {Object.keys(transformData).length > 0 && (
                    <TreeComponent transformData={treeTransformData} />
                  )}
                </div>
              ) : (
                // <CustomTable
                //   language={i18n.language}
                //   externalData={matchingRecords}
                //   columnsWithoutPin={columns}
                //   loading={false}
                //   title={" "}
                //   pinningInitialState={pinningInitialState}
                //   setParentSelectedValues={() => {}}
                //   prefix="parent-tenant-details"
                //   pinnedRowHeight={62}
                //   // maxTableHeight={400}
                //   showFilter={false}
                //   minimalTable={true}
                //   hideActionColumn={true}
                //   noData={noData}
                //   isExpandable={false}
                //   allowRowPinning={false}
                //   showAccessibility={true}
                //   pinCondition={(row) => {
                //     return true;
                //   }}
                //   expandedViewRow={(row) => {
                //     const children = childrenRecords.filter(
                //       (record) => record.ParentOrgId === row.original.OrgId
                //     );
                //     return <div className="">{renderChildTable(children)}</div>;
                //   }}
                //   selectableCondition={(row, isHeader) => {
                //     return false;
                //   }}
                //   autoFillData={false}
                //   rowCustomID={(row) => {
                //     return row.PromotedRecordId;
                //   }}
                //   customSubRows={(row) => {
                //     return row.DestinationRecords;
                //   }}
                //   allowExpansion={false}
                //   isExpandedByDefault={true}
                // />
                <HierarchyComponent
                  data={tenantOrgs}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  setInfoPopupOpen={setInfoPopupOpen}
                  setCurrentBranch={setCurrentBranch}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return isLoading ? (
    <Loader dots withContainer />
  ) : (
    <>
      <div className="w-full">
        <div className="border-2 border-gray-200 rounded mr-5 text-black">
          {renderTables()}
        </div>
        {openFileDropModal && (
          <PopupComponent
            externalClassName={"w-[40vw] !max-w-[550px]"}
            open={openFileDropModal}
            rawValue={
              <>
                <div className="py-5">
                  <TitleComponent
                    text="Upload Data Acquisition File"
                    size="lg"
                    description="Manually upload a data acquisition file."
                  />
                </div>
                <CustomFileUploader
                  key={`fileDrop_${refreshFileDrop}`}
                  classes="h-[100px]"
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                />
              </>
            }
            setPopupOpen={setOpenFileDropModal}
            addBlur
            hideCellValue={true}
          />
        )}
        {openConfirmationModal && (
          <Confirmationpopup
            singleAction={openConfirmationModal}
            setOpenConfirmationAction={setOpenConfirmation}
          />
        )}
      </div>
      {infoPopupOpen === currentBranch?.id && (
        <PopupComponent
          externalClassName={"w-[40vw] !max-w-[550px]"}
          open={infoPopupOpen}
          rawValue={
            <div>
              <p className="font-semibold text-gray-700">Contact Info: </p>
              {currentBranch.addresses.length > 0 && (
                <div className="flex flex-col gap-2 italic opacity-60 items-start justify-between px-5 pl-0 mt-4">
                  <div className="flex flex-col gap-2 items-start">
                    <span className="font-semibold">
                      {translate("tenant-details.address")}:
                    </span>
                    {currentBranch.addresses.map((address, index) => (
                      <span key={index}>
                        <Highlighter
                          highlightClassName="text-red-500 bg-transparent"
                          searchWords={[searchTerm]}
                          autoEscape={true}
                          textToHighlight={address}
                        />
                      </span>
                    ))}
                  </div>
                  <div className="flex flex-col gap-2">
                    {currentBranch.contacts
                      .slice() // Create a shallow copy to avoid mutating the original array
                      .sort((a, b) =>
                        a.ContactTypeTag.localeCompare(b.ContactTypeTag)
                      ) // Sort by ContactTypeTag
                      .reduce(
                        (acc, contact, index, array) => {
                          const { ContactTypeTag } = contact;
                          if (
                            !acc.lastType ||
                            acc.lastType !== ContactTypeTag
                          ) {
                            acc.lastType = ContactTypeTag;
                            acc.currentIndex = 0;
                          } else {
                            acc.currentIndex += 1;
                          }
                          acc.contacts.push({
                            ...contact,
                            displayIndex: acc.currentIndex,
                          });
                          return acc;
                        },
                        { contacts: [], lastType: null, currentIndex: 0 }
                      )
                      .contacts.map((contact, index) => {
                        return (
                          <span key={index} className="flex flex-col mt-2">
                            {" "}
                            <span className="font-semibold">
                              {`${
                                contact.ContactTypeTag === "MASTERMOBILE"
                                  ? translate("tenant-details.phone")
                                  : contact.ContactTypeTag === "MASTEREMAIL"
                                  ? translate("tenant-details.email")
                                  : translate("tenant-details.contact")
                              } ${contact.displayIndex + 1}:`}
                            </span>
                            <Highlighter
                              highlightClassName="text-red-500 bg-transparent"
                              searchWords={[searchTerm]}
                              autoEscape={true}
                              textToHighlight={contact.ContactValue}
                            />
                          </span>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          }
          setPopupOpen={setInfoPopupOpen}
          addBlur
          hideCellValue={true}
        />
      )}
    </>
  );
};
export default TenantDetails;
