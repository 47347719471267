import React, { useState } from "react";
import clsx from "clsx";

const Dropdown = ({
  filter,
  selectedOption,
  handleChange,
  index,
  defaultValue = "",
  className,
  largerDropdown = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    handleChange({ target: { value: option.value } }, filter);
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div
          onClick={() => setIsOpen(false)}
          className="fixed backdrop-filter top-0 left-0 w-full h-full bg-[#000000] bg-opacity-15 "
        />
      )}
      <div className="relative">
        {filter.title && (
          <span className="flex text-sm mb-1 text-gray-500 font-semibold">
            {filter.title}
          </span>
        )}
        <div
          className={clsx(
            "flex items-center justify-between w-full py-[10px] px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 cursor-pointer",
            largerDropdown ? "max-w-xl" : "max-w-md",
            className
          )}
          onClick={toggleDropdown}
        >
          <span className="truncate">
            {selectedOption?.displayName || defaultValue}
          </span>
          <span
            className={clsx(
              "transform transition-transform duration-200",
              isOpen ? "rotate-180" : "rotate-0"
            )}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          </span>
        </div>
        {isOpen && (
          <div
            className={clsx(
              "absolute max-h-80 overflow-y-auto w-full bg-white border border-gray-300 rounded-md shadow-lg z-10",
              largerDropdown ? "max-w-xl" : "max-w-lg"
            )}
          >
            {filter.values.map((option) => (
              <div
                key={option.value}
                className={clsx(
                  "py-2 px-3 cursor-pointer text-sm hover:bg-gray-100 border-b border-gray-300",
                  option.className,
                  "line-clamp-3"
                )}
                onClick={() => handleOptionClick(option)}
              >
                {option.displayName}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Dropdown;
