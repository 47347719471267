import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import PopupComponent from "@components/PopupComponent";
import Loader from "@components/LoaderComponent/loader";
import FilterComponent from "@components/FilterComponent";
import { fetchDynamicFilters, humanize } from "@helpers/helpers";
import {
  saveToLocalStorage,
  getLocalConfig,
} from "@components/CustomTable/helpers";
import { Observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import clsx from "clsx";

function refactorFilters(filters, translate) {
  const overrideKeys = {
    // ReconciliationStreamTag: "ReconciliationStream",
  };
  const translations = {
    AreaId: translate("charts.filters.AreaId"),
    OutletId: translate("charts.filters.OutletId"),
  };

  const mappedFilters = filters.map((filter) => ({
    key: overrideKeys[filter.ColumnName] || filter.ColumnName,
    displayName: translations[filter.ColumnName] || humanize(filter.ColumnName),
    isMandatory: filter.IsMandatory,
    components: [
      {
        key: overrideKeys[filter.ColumnName] || filter.ColumnName,
        displayName:
          translations[filter.ColumnName] || humanize(filter.ColumnName),
        type: "checkboxGroup",
        values: [
          {
            displayName: "All",
            value: null,
            selectable: false,
            children: filter.Values.map((value) => ({
              displayName: value.Label,
              value: value.Value,
              selectable: true,
            })),
          },
        ],
        filterType: "checkbox",
        operator: "EQ",
      },
    ],
  }));

  const businessDayFilter = {
    key: "businessDay",
    displayName: translate("charts.filters.businessDay"),
    components: [
      {
        name: translate("charts.filters.selectDate"),
        type: "title",
        key: "title",
      },
      {
        name: translate("charts.filters.businessDay"),
        type: "dateInput",
        inputType: "date",
        key: "BusinessDay",
        placeHolder: translate("charts.filters.businessDay"),
        prefix: "",
        filterType: "date",
      },
    ],
  };
  return [
    {
      mainFilters: [...mappedFilters, businessDayFilter],
    },
  ];
}

const getCurrentFormattedDate = () => {
  const date = new Date();

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const ChartComponent = ({
  title,
  ChartTag,
  ChartTypeTag,
  ScaleTag,
  maxSeriesPerPage,
  uniqueID,
  myStore,
  dynamicFilterTag,
  filterMinHeight,
}) => {
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const [parentSelectedValues, setParentSelectedValues] = useState({});
  const [appliedFilters, setAppliedFilters] = useState(
    getLocalConfig(`chartsFilters_${uniqueID}`, "") || {}
  );
  const [filterDisplayValues, setFilterDisplayValues] = useState({});
  const [filters, setFilters] = useState([]);
  const [lastUpdated, setLastUpdated] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { t: translate, i18n } = useTranslation();

  useEffect(() => {
    myStore.setChartParams(
      ChartTag,
      ChartTypeTag,
      ScaleTag,
      maxSeriesPerPage,
      uniqueID
    );
    myStore.fillData((data) => {
      setLastUpdated(getCurrentFormattedDate());
    }, appliedFilters);
    fetchDynamicFilters(dynamicFilterTag, (newFilters) => {
      setFilters(newFilters);
    });
  }, [ChartTag, ChartTypeTag, ScaleTag]);

  if (myStore.error) {
    throw myStore.error;
  }

  const saveAndApplyFilter = (filters) => {
    saveToLocalStorage(`chartsFilters_${uniqueID}`, filters);
    setAppliedFilters(filters);
    myStore.setAppliedFilters(filters, true, () => {
      console.log(myStore.loading);
      setLastUpdated(getCurrentFormattedDate());
    });
  };

  const selectedFiltersDisplay = {
    PaymentMethodTag: translate("charts.filters.PaymentMethodTag"),
    ReconciliationStreamTag: translate(
      "charts.filters.ReconciliationStreamTag"
    ),
    AreaId: translate("charts.filters.AreaId"),
    OutletId: translate("charts.filters.OutletId"),
    TransactionTerminalCode: translate(
      "charts.filters.TransactionTerminalCode"
    ),
    BusinessDay: translate("charts.filters.businessDay"),
  };
  const commonPaginationArrowsStyles =
    "inline-flex items-center h-fit justify-center px-5 disabled:opacity-10 text-primary-400 hover:text-primary-600 disabled:cursor-not-allowed hover:scale-110 transition-transform transition-colors duration-300";

  return (
    <Observer>
      {() => {
        return (
          <div className="p-4 bg-white rounded shadow-md">
            <div className="flex justify-between mb-4 items-baseline">
              <div className="flex gap-4 items-center">
                <h2 className="text-xl font-bold">{title}</h2>
              </div>
              <span className="text-xs text-primary-300">
                {lastUpdated &&
                  !myStore.loading &&
                  `Last updated:  ${lastUpdated}`}
              </span>
            </div>
            <div
              className={clsx(
                "flex gap-4 justify-between relative",
                myStore.loading && "pointer-events-none opacity-50"
              )}
            >
              <FilterComponent
                appliedFilters={appliedFilters}
                updateAppliedFilters={saveAndApplyFilter}
                openFilter={openFilterPopup}
                setOpenFilter={setOpenFilterPopup}
                filterDisplayValues={filterDisplayValues}
                filters={refactorFilters(filters, translate)}
                selectedFiltersDisplay={selectedFiltersDisplay}
                setSelectedValues={(values) => {
                  setSelectedValues(values);
                  setParentSelectedValues(values);
                }}
                selectedValues={selectedValues}
                multipleValueColumns={[]}
                extraParentClass="!flex-row items-center"
                isScrollable={false}
                prefix={uniqueID}
                minHeight={filterMinHeight}
              />
            </div>
            {myStore.loading ? (
              <div className="relative min-h-[350px] flex items-center justify-center">
                <Loader dots />
              </div>
            ) : (
              <div>
                <div className="flex justify-between items-center">
                  {myStore.data?.shouldPaginate && (
                    <button
                      onClick={() => myStore.handlePreviousPage()}
                      disabled={
                        myStore.lastPage === myStore.offset &&
                        myStore.offset !== 0
                      }
                      className={clsx(
                        commonPaginationArrowsStyles,
                        "pl-2 pr-4"
                      )}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
                        />
                      </svg>
                    </button>
                  )}
                  <input
                    type="hidden"
                    id={`current_page_${uniqueID}`}
                    value={myStore.currentPage}
                  />
                  {myStore.loading ? (
                    <div className="relative min-h-[350px] w-full flex items-center justify-center">
                      <Loader dots />
                    </div>
                  ) : myStore.data.chartOptions ? (
                    <Chart
                      options={
                        myStore.data.chartOptions
                        // {
                        //   ...myStore.data.chartOptions,
                        //   xaxis: {
                        //     ...myStore.data.chartOptions.xaxis,
                        //     categories:
                        //       myStore.data.chartOptions.xaxis.categories,
                        //   },
                        // }
                        // myStore.data.chartOptions
                        // ? {
                        //     ...myStore.data.chartOptions,
                        //     xaxis: {
                        //       ...myStore.data.chartOptions.xaxis,
                        //       categories:
                        //         myStore.data.categories.length > 0
                        //           ? myStore.data.categories[
                        //               myStore.currentPage
                        //             ]
                        //           : myStore.data.chartOptions.xaxis
                        //               .categories,
                        //     },
                        //   }
                        // : {}
                      }
                      series={
                        myStore.data.chartSeries
                        // !!myStore.data?.paginatedChartSeries?.[0]
                        //   ? myStore.data.paginatedChartSeries[
                        //       myStore.currentPage
                        //     ]
                        //   : myStore.data.chartSeries
                      }
                      type="line"
                      style={{ width: "100%" }}
                      height={350}
                    />
                  ) : (
                    (!myStore.data.chartSeries ||
                      !myStore.data.chartOptions) && (
                      <div className="flex w-full items-center justify-center min-h-[350px]">
                        <span className="text-xl text-primary-300">
                          {errorMessage || "No data available"}
                        </span>
                      </div>
                    )
                  )}
                  {myStore.data?.shouldPaginate && (
                    <button
                      onClick={() => myStore.handleNextPage()}
                      disabled={
                        myStore.offset === 0
                        // myStore.currentPage ===
                        // myStore.data.paginatedChartSeries.length - 1
                      }
                      className={clsx(
                        commonPaginationArrowsStyles,
                        "pr-2 pl-4"
                      )}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                        />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            )}
            {myStore.popupData && (
              <PopupComponent
                open={myStore.popupData !== null}
                innerHtml={myStore.popupData}
                setPopupOpen={() => {
                  myStore.popupData = null;
                }}
              />
            )}
          </div>
        );
      }}
    </Observer>
  );
};

export default ChartComponent;
