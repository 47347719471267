import React, { useEffect, useState } from "react";
import CustomTable from "@components/CustomTable";
import myStore from "./store";
import { Observer } from "mobx-react";
import {
  fetchDynamicFilters,
  transformData,
  executeCorrectiveActions,
  GetSFMDisplay,
  resolveMismatchColumn,
  FormatWithCommas,
  resolveAgingTierColumn,
} from "@helpers/helpers";
import SfmModalStore from "@stores/SfmModalStore";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const ExceptionsRecordComponent = () => {
  const { t: translate, i18n } = useTranslation();

  const [dynamicFilters, setDynamicFilters] = useState([]);
  useEffect(() => {
    fetchDynamicFilters("ExceptionFilters", (newFilters) => {
      setDynamicFilters(newFilters);
    });
  }, []);

  const getDynamicFilters = (key, onlyChildren = false) => {
    return transformData(
      (!!dynamicFilters &&
        dynamicFilters.length > 0 &&
        dynamicFilters.find((filter) => filter.ColumnName === key)?.Values) ||
        [],
      onlyChildren
    );
  };

  const externalDisplays = [
    ...getDynamicFilters("ReconciliationStreamTag"),
    ...getDynamicFilters("AgingTierTag"),
  ];

  const pinningInitialState = ["pin", "ReconciliationRunStatusName"];

  const columns = [
    {
      accessorKey: "ReconciliationStreamName",
      header: translate("exceptions-record.columns.ReconciliationStreamName"),
      canFilter: true,
      isMultiple: true,
      sortable: true,
      size: 230,
      truncate: true,
      processFilterValue: (value, row) => {
        return row.ReconciliationStreamTag;
      },
      processFilterKey: (value, row) => {
        return "ReconciliationStreamTag";
      },
      filterType: "checkbox",
      operator: "EQ",
    },
    {
      accessorKey: "AreaName",
      header: translate("exceptions-record.columns.AreaName"),
      processFilterValue: (value, row) => {
        return row.AreaId.toString();
      },
      processFilterKey: (value, row) => {
        return "AreaId";
      },
      size: 150,
      canFilter: true,
      isMultiple: true,
      sortable: true,
      truncate: false,
      filterType: "checkbox",
      operator: "EQ",
    },
    {
      accessorKey: "OutletName",
      header: translate("exceptions-record.columns.OutletName"),
      processFilterValue: (value, row) => {
        return row.OutletId.toString();
      },
      processFilterKey: (value, row) => {
        return "OutletId";
      },
      size: 150,
      canFilter: true,
      isMultiple: false,
      sortable: true,
      truncate: false,
      filterType: "string",
      operator: "EQ",
    },
    {
      accessorKey: "TransactionTerminalCode",
      header: translate("exceptions-record.columns.TransactionTerminalCode"),
      processFilterValue: (value, row) => {
        return row.TransactionTerminalCode;
      },
      processFilterKey: (value, row) => {
        return "TransactionTerminalCode";
      },
      size: 150,
      canFilter: true,
      sortable: true,
      filterType: "string",
      operator: "EQ",
    },
    {
      accessorKey: "RecordMatchingKey",
      header: translate("exceptions-record.columns.RecordMatchingKey"),
      size: 150,
      sortable: true,
      canPin: false,
      canFilter: false,
      filterType: "string",
      operator: "EQ",
    },
    {
      accessorKey: "BusinessDay",
      header: translate("exceptions-record.columns.BusinessDay"),
      size: 150,
      sortable: true,
      canPin: false,
    },
    {
      accessorKey: "CreationDate",
      header: translate("exceptions-record.columns.CreationDate"),
      size: 150,
      sortable: true,
      canPin: false,
      canFilter: true,
      truncate: true,
      filterType: "date",
      splitCellValue: "T",
    },
    {
      accessorKey: "SourceAmount",
      header: translate("exceptions-record.columns.SourceAmount"),
      size: 200,
      sortable: true,
      canPin: false,
      showThousandSeperator: true,
      cell: ({ row }) => {
        return (
          row.original.SourceAmount && (
            <span className="flex flex-col items-center justify-center group font-normal">
              <span>{`${row.original.Currency} ${FormatWithCommas(
                row.original.SourceAmount
              )}`}</span>
            </span>
          )
        );
      },
    },
    {
      accessorKey: "DestinationAmount",
      header: translate("exceptions-record.columns.DestinationAmount"),
      size: 200,
      sortable: true,
      canPin: false,
      canFilter: false,
      showThousandSeperator: true,
      cell: ({ row }) => {
        return (
          row.original.DestinationAmount && (
            <span className="flex flex-col items-center justify-center group font-normal">
              <span>{`${row.original.Currency} ${FormatWithCommas(
                row.original.DestinationAmount
              )}`}</span>
            </span>
          )
        );
      },
    },
    {
      accessorKey: "Mismatches",
      header: translate("exceptions-record.columns.Mismatches"),
      size: 210,
      sortable: true,
      canPin: false,
      canFilter: false,
      cell: ({ row }) => {
        return resolveMismatchColumn(row.original.Mismatches);
      },
    },
    {
      accessorKey: "AgingTypeTag",
      header: translate("exceptions-record.columns.AgingTier"),
      size: 130,
      cell: ({ row }) => {
        return resolveAgingTierColumn(
          row.original.AgingTier,
          row.original.AgingTier.AgingTypeTag
        );
      },
    },
    {
      accessorKey: "NumberOfAttempts",
      header: translate("exceptions-record.columns.NumberOfAttempts"),
      size: 130,
      cellAction: (value, context) => {
        window.location.href = `/exception-attempt-details?id=${context.row.original.MatchingRecordId}`;
      },
      showThousandSeperator: true,
    },
    {
      accessorKey: "MatchingRecordId",
      header: translate("exceptions-record.columns.MatchingRecordId"),
      size: 250,
      truncate: true,
      cellAction: (value, context) => {
        window.location.href = `/exception-attempt-details?id=${context.row.original.MatchingRecordId}`;
      },
      truncate: true,
      cellActionCheck: (value, context) =>
        context.row.original.NumberOfAttempts > 0,
    },
  ];

  const filterDisplayValues = {
    trigger: [
      {
        displayName: translate("exceptions-record.filters-display.Manual"),
        value: "Manual",
      },
      {
        displayName: translate("exceptions-record.filters-display.Schedule"),
        value: "Schedule",
      },
    ],
  };

  const filters = [
    {
      mainFilters: [
        {
          key: "ReconciliationStreamName",
          displayName: translate(
            "exceptions-record.filters.ReconciliationStreamName"
          ),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          ),
          components: [
            {
              name: translate(
                "exceptions-record.filters.ReconciliationStreamName"
              ),
              type: "title",
              key: "title",
            },
            {
              name: translate("exceptions-record.filters.ReconciliationRunId"),
              type: "numberInput",
              key: "ReconciliationRunId",
              placeHolder: translate("shared.filterRunID"),
              decimalPoint: 0,
              step: 1,
              operator: "EQ",
              filterType: "string",
              hasDecimal: false,
            },
            {
              name: translate(
                "exceptions-record.filters.ReconciliationStreamTag"
              ),
              type: "checkboxGroup",
              key: "ReconciliationStreamTag",
              values: getDynamicFilters("ReconciliationStreamTag"),
              filterType: "checkbox",
              operator: "EQ",
            },
          ],
        },
        {
          key: "Location",
          displayName: translate("exceptions-record.filters.Location"),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          ),
          components: [
            {
              name: translate("exceptions-record.filters.Reconciliations"),
              type: "title",
              key: "title",
            },
            {
              name: translate("exceptions-record.filters.AreaId"),
              type: "checkboxGroup",
              key: "AreaId",
              values: getDynamicFilters("AreaId"),
              filterType: "checkbox",
              operator: "EQ",
            },
            {
              name: translate("exceptions-record.filters.OutletId"),
              type: "checkboxGroup",
              key: "OutletId",
              values: getDynamicFilters("OutletId"),
              filterType: "checkbox",
              operator: "EQ",
            },
            {
              name: translate(
                "exceptions-record.filters.TransactionTerminalCode"
              ),
              type: "checkboxGroup",
              key: "TransactionTerminalCode",
              values: getDynamicFilters("TransactionTerminalCode"),
              filterType: "checkbox",
              operator: "EQ",
            },
          ],
        },
        {
          key: "AgingTierTag",
          displayName: translate("exceptions-record.filters.AgingTier"),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          ),
          components: [
            {
              name: translate("exceptions-record.filters.AgingTier"),
              type: "title",
              key: "title",
            },
            {
              name: translate("exceptions-record.filters.AgingTier"),
              type: "checkboxGroup",
              key: "AgingTierTag",
              values: getDynamicFilters("AgingTierTag"),
              filterType: "checkbox",
              operator: "EQ",
            },
          ],
        },
        {
          key: "NextReconciliationRun",
          displayName: translate("exceptions-record.filters.dates"),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
              />
            </svg>
          ),
          components: [
            {
              name: translate("exceptions-record.filters.PleaseSelectDate"),
              type: "title",
              key: "title",
            },
            {
              name: translate("exceptions-record.filters.CreationDate"),
              type: "dateInput",
              inputType: "date",
              key: "CreationDate",
              placeHolder: translate("exceptions-record.filters.CreationDate"),
              prefix: "",
              filterType: "date",
            },
            {
              name: translate("exceptions-record.filters-display.BusinessDay"),
              type: "dateInput",
              inputType: "date",
              key: "BusinessDay",
              placeHolder: translate(
                "exceptions-record.filters-display.BusinessDay"
              ),
              prefix: "",
              filterType: "date",
            },
          ],
        },
        {
          key: "Amounts",
          displayName: translate("exceptions-record.filters.Amounts"),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          ),
          components: [
            {
              name: translate("exceptions-record.filters.Amounts"),
              type: "title",
              key: "title",
            },
            {
              name: translate("exceptions-record.filters.SourceAmount"),
              type: "numberInput",
              key: "SourceAmount",
              filterType: "amount",
              inputType: "number",
            },
            {
              name: translate("exceptions-record.filters.DestinationAmount"),
              type: "numberInput",
              key: "DestinationAmount",
              filterType: "amount",
              inputType: "number",
            },
          ],
        },
        {
          key: "Mismatches",
          displayName: translate("run-matching-records.filters.Mismatch"),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          ),
          components: [
            {
              name: translate("run-matching-records.filters.Mismatch"),
              type: "title",
              key: "title",
            },
            {
              name: translate("run-matching-records.filters.Mismatch"),
              type: "checkboxGroup",
              key: "MismatchTag",
              values: getDynamicFilters("MismatchTag"),
              filterType: "checkbox",
              operator: "EQ",
            },
            {
              name: translate("run-matching-records.filters.MismatchSeverity"),
              type: "checkboxGroup",
              key: "MismatchSeverityTag",
              values: getDynamicFilters("MismatchSeverityTag"),
              filterType: "checkbox",
              operator: "EQ",
            },
          ],
        },
      ],
    },
  ];
  const selectedFiltersDisplay = {
    ReconciliationStreamTag: translate("filters-display.StreamName"),
    BusinessDay: translate("filters-display.BusinessDay"),
    AreaId: translate("filters-display.Area"),
    OutletId: translate("filters-display.Outlet"),
    TransactionTerminalCode: translate("filters-display.Terminal"),
    RecordReference: translate("filters-display.RecordReference"),
    RecordDate: translate("filters-display.RecordDate"),
    ReconciliationRunId: translate("filters-display.RunID"),
    NumberOfAttempts: translate("filters-display.NumberOfAttempts"),
    IsInTransitCritical: translate("filters-display.CriticalRecordsOnly"),
    CreationDate: translate("filters-display.CreationDate"),
    AgingTierTag: translate("filters-display.AgingTierTag"),
    SourceAmount: translate("filters-display.SourceAmount"),
    DestinationAmount: translate("filters-display.DestinationAmount"),
    MismatchTag: translate("filters-display.MismatchTag"),
    MismatchSeverityTag: translate("filters-display.MismatchSeverityTag"),
  };
  const actions = {
    FORCE_CLEAR: {
      actionTypeTag: "FORCE_CLEAR",
      name: translate("exceptions-record.actions.forceClear"),
      actionFN: (
        row,
        ids,
        selectedReason,
        reasonDescription,
        sourceAmount,
        destinationAmount
      ) => {
        executeCorrectiveActions({
          ActionTypeTag: "FORCE_CLEAR",
          ExceptionRecords: row?.id ? [row.id] : ids.split(","),
          ActionReasonTag: selectedReason,
          ActionReasonDescription: reasonDescription,
          AdjustedSourceAmount: sourceAmount,
          AdjustedDestinationAmount: destinationAmount,
          callback: (data) => {
            const sfmData = GetSFMDisplay(data.SFM);
            SfmModalStore.open(sfmData);
          },
          store: myStore,
        });
      },
      severity: "high",
      confirmation: true,
      confirmationMessage: translate(
        "exceptions-record.confirmation.forceClear"
      ),
      showReason: true,
      hasAmounts: true,
      largerPopup: true,
    },
    UNDO_EXCEPTION: {
      actionTypeTag: "UNDO_EXCEPTION",
      name: translate("exceptions-record.actions.undoException"),
      actionFN: (row, ids, selectedReason, reasonDescription) => {
        executeCorrectiveActions({
          ActionTypeTag: "UNDO_EXCEPTION",
          ExceptionRecords: row?.id ? [row.id] : ids.split(","),
          ActionReasonTag: selectedReason,
          ActionReasonDescription: reasonDescription,
          callback: (data) => {
            const sfmData = GetSFMDisplay(data.SFM);
            SfmModalStore.open(sfmData);
          },
          store: myStore,
        });
      },
      confirmation: true,
      confirmationMessage: translate(
        "exceptions-record.confirmation.undoException"
      ),
      severity: "high",
      showReason: true,
    },
    ATTEMPT_MATCHING: {
      actionTypeTag: "ATTEMPT_MATCHING",
      name: translate("exceptions-record.actions.attemptMatching"),
      actionFN: (row, ids, selectedReason, reasonDescription) => {
        window.location.href = `/exception-attempt-matching?id=${row.original.MatchingRecordId}`;
      },
    },
  };

  const GlobalActions = {
    FORCE_CLEAR: {
      actionTypeTag: "FORCE_CLEAR",
      name: translate("shared.globalActions.forceClear"),
      actionFN: (row, ids, selectedReason, reasonDescription) => {
        executeCorrectiveActions({
          ActionTypeTag: "FORCE_CLEAR",
          ExceptionRecords: row?.id ? [row.id] : ids.split(","),
          ActionReasonTag: selectedReason,
          ActionReasonDescription: reasonDescription,
          callback: (data) => {
            const sfmData = GetSFMDisplay(data.SFM);
            SfmModalStore.open(sfmData, () => {
              window.location.reload();
            });
          },
          store: myStore,
        });
      },
      severity: "high",
      confirmation: true,
      confirmationMessage: translate(
        "shared.globalActions.confirmation.forceClear"
      ),
      showReason: true,
    },
    UNDO_EXCEPTION: {
      actionTypeTag: "UNDO_EXCEPTION",
      name: translate("shared.globalActions.undoException"),
      actionFN: (row, ids, selectedReason, reasonDescription) => {
        executeCorrectiveActions({
          ActionTypeTag: "UNDO_EXCEPTION",
          ExceptionRecords: row?.id ? [row.id] : ids.split(","),
          ActionReasonTag: selectedReason,
          ActionReasonDescription: reasonDescription,
          callback: (data) => {
            const sfmData = GetSFMDisplay(data.SFM);
            SfmModalStore.open(sfmData, () => {
              window.location.reload();
            });
          },
          store: myStore,
        });
      },
      severity: "high",
      confirmation: true,
      confirmationMessage: translate(
        "shared.globalActions.confirmation.undoException"
      ),
      showReason: true,
    },
  };

  return (
    <Observer>
      {() => {
        return (
          <CustomTable
            store={myStore}
            columnsWithoutPin={columns}
            loading={myStore.loading}
            pinCondition={(row) => {
              return row.IsScheduled;
            }}
            filterDisplayValues={filterDisplayValues}
            selectedFiltersDisplay={selectedFiltersDisplay}
            filters={filters}
            pinningInitialState={pinningInitialState}
            setParentSelectedValues={() => {}}
            actions={actions}
            prefix="ExceptionsRecord"
            title={translate("exceptions-record.title")}
            pinnedRowHeight={57}
            selectableCondition={(row) => {
              return true;
            }}
            GlobalActions={GlobalActions}
            showExportButton={true}
            activityTag="ExportExceptionRecords"
            language={i18n.language}
            maxTableHeight={"calc(100dvh - 300px)"}
            externalDisplays={externalDisplays}
          />
        );
      }}
    </Observer>
  );
};

export default ExceptionsRecordComponent;
