import React, { useState, forwardRef, useEffect } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const SearchableDropdown = forwardRef(
  ({
    filter,
    selectedOption,
    handleChange,
    index,
    defaultValue = "",
    className,
    isSearchable = false,
    hasMultiSelect = false,
    hasSelectAll = false,
    beforeOpen,
    defaultOpenState = false,
  }) => {
    const { t: translate } = useTranslation();

    const [searchTerm, setSearchTerm] = useState("");
    const [selectedOptions, setSelectedOptions] = useState(
      !!selectedOption.value ? [selectedOption] : []
    );
    const [isOpen, setIsOpen] = useState(defaultOpenState);

    useEffect(() => {
      setIsOpen(defaultOpenState);
    }, [defaultOpenState]);

    const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
    };

    const handleSelectChange = (e) => {
      const { value } = e.target;
      let newSelectedOptions = [...selectedOptions];
      if (newSelectedOptions.includes(value)) {
        newSelectedOptions = newSelectedOptions.filter(
          (option) => option !== value
        );
      } else {
        newSelectedOptions.push(value);
      }
      setSelectedOptions(hasMultiSelect ? newSelectedOptions : [value]);
      handleChange({
        target: {
          value: hasMultiSelect ? newSelectedOptions.join(",") : value,
        },
      });
    };

    const allValuesSelected = filter.values.every((option) =>
      selectedOptions.includes(option.value)
    );

    const handleSelectAll = () => {
      const allValues = filter.values.map((option) => option.value);
      if (allValuesSelected) {
        setSelectedOptions([]);
      } else {
        setSelectedOptions(allValues);
        handleChange({
          target: {
            value: allValues.join(","),
          },
        });
      }
    };

    const handleClearSearch = () => {
      setSearchTerm("");
    };

    const filteredOptions = filter.values.filter((option) =>
      option.displayName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const getSelectedDisplayNames = () => {
      if (allValuesSelected) {
        return "All";
      }
      return filter.values
        .filter((option) => selectedOptions.includes(option.value))
        .map((option) => option.displayName)
        .join(",");
    };

    return (
      <>
        {isOpen && (
          <div
            onClick={() => setIsOpen(false)}
            className="fixed backdrop-filter top-0 left-0 w-full h-full bg-[#000000] bg-opacity-15 "
          />
        )}
        <div className="relative">
          {filter.title && (
            <span className="flex text-sm mb-1 text-gray-500 font-semibold">
              {filter.title}
            </span>
          )}
          <div
            className={clsx(
              "block w-full  py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500",
              className,
              "truncate flex justify-between items-center cursor-pointer"
            )}
            onClick={() => {
              beforeOpen && beforeOpen();
              setIsOpen(!isOpen);
            }}
          >
            <span className="max-w-[300px] truncate overflow-ellipsis">
              {getSelectedDisplayNames() || defaultValue}
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-5 w-5 transform transition-transform ${
                isOpen ? "rotate-180" : "rotate-0"
              }`}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          {isOpen && (
            <div className="absolute p-1 z-10 mt-1 w-full max-w-md bg-white border border-gray-300 rounded-md shadow-lg">
              {isSearchable && (
                <div className="relative p-2">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Search..."
                    className="block w-full py-1 px-3 border border-gray-200 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                  />
                  <button
                    type="button"
                    onClick={handleClearSearch}
                    className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18 18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              )}
              <div className="px-4">
                {hasSelectAll && hasMultiSelect && (
                  <button
                    type="button"
                    onClick={handleSelectAll}
                    className="block text-xs text-primary-500 hover:underline px-2 py-1"
                  >
                    {!allValuesSelected
                      ? translate("shared.selectAll")
                      : translate("shared.deselectAll")}
                  </button>
                )}
                <div className="max-h-60 overflow-y-auto">
                  {filteredOptions.map((option) => (
                    <label key={option.value} className="flex items-center p-2">
                      <input
                        type="checkbox"
                        value={option.value}
                        checked={selectedOptions.includes(option.value)}
                        onChange={handleSelectChange}
                        className="form-checkbox text-primary-500 h-4 w-4 cursor-pointer mr-2"
                      />
                      {option.displayName}
                    </label>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
);

SearchableDropdown.displayName = "SearchableDropdown";

export default SearchableDropdown;
