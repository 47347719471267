import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  fetchDynamicFilters,
  ExportReport,
  addToDownloadCenter,
  extractFileName,
  extractFileSize,
  updateDownloadItem,
  GetSFMDisplay,
} from "@helpers/helpers";
import InputComponent from "@components/FilterComponent/components/InputComponent";
import ButtonComponent from "@components/ButtonComponent";
import DownloadModalStore from "@stores/DownloadModalStore";
import ReportsModalStore from "@stores/ReportsModalStore";
import { toast } from "react-toastify";
import Loader from "@components/LoaderComponent/loader";
import SearchableDropwdown from "@components/SearchableDropwdown";
import Dropdown from "@components/FilterComponent/components/DropDownComponent";

const ReportsComponent = ({ reports }) => {
  const { t: translate } = useTranslation();

  function transformData(data) {
    return {
      title: "Report Types",
      key: "reportType",
      values: data.map((item) => ({
        value: item.ReportTypeTag,
        displayName: item.ReportTypeName,
      })),
    };
  }

  const [dynamicFilters, setDynamicFilters] = useState([]);
  const [selectedReportType, setSelectedReportType] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [toastID, setToastID] = useState(null);

  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleChange = (event) => {
    setSelectedReportType(event.target.value);
    setIsLoading(true);
    fetchDynamicFilters(
      "MonthlyPOSTransactionsReconciliationFilters",
      (newFilters) => {
        setSelectedFilters({});
        setDynamicFilters(newFilters);
        setIsLoading(false);
      }
    );
  };

  const reorderedFilters = dynamicFilters.sort((a, b) => {
    if (a.ColumnName === "Month") return -1;
    if (b.ColumnName === "Month") return 1;
    return 0;
  });

  const filtersDisplayName = {
    Month: translate("reports.filters.Month"),
    AreaId: translate("reports.filters.AreaId"),
    OutletId: translate("reports.filters.OutletId"),
  };

  const handleDateChange = (event, filter) => {
    let value = event.target.value;
    const dateParts = value.split("-");

    const today = new Date();
    const todayString = today.toISOString().split("T")[0];
    if (dateParts[0].length > 4) {
      dateParts[0] = dateParts[0].slice(0, 4);
      value = dateParts.join("-");
    }
    if (value > todayString) {
      setTimeout(() => {
        setToastID(toast.error(translate("shared.invalid-date")));
      }, 1500);
      return;
    } else {
      toast.update(toastID, {
        render: translate("shared.valid-date"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      setSelectedFilters({
        ...selectedFilters,
        [filter.ColumnName]: value,
      });
    }
  };

  return (
    <div className="relative">
      {isLoading ? (
        <Loader dots />
      ) : (
        <div className="flex flex-col gap-2">
          <Dropdown
            key={0}
            filter={transformData(reports)}
            handleChange={handleChange}
            defaultValue={translate("reports.selectReportType")}
            selectedOption={{ value: selectedReportType }}
            index={0}
          />
          {dynamicFilters.length > 0 &&
            reorderedFilters.map((filter, index) => {
              if (
                filter.ColumnName === "AreaId" ||
                filter.ColumnName === "OutletId"
              ) {
                const transformedFilter = {
                  title:
                    filtersDisplayName[filter.ColumnName] || filter.ColumnName,
                  key: filter.ColumnName,
                  values: filter.Values.map((value) => ({
                    value: value.Value,
                    displayName: value.Label,
                    className: "",
                  })),
                };

                return (
                  <SearchableDropwdown
                    key={`${index + 1}}`}
                    filter={transformedFilter}
                    handleChange={(event) => {
                      setSelectedFilters({
                        ...selectedFilters,
                        [filter.ColumnName]: event.target.value,
                      });
                    }}
                    defaultValue={`Select ${
                      filtersDisplayName[filter.ColumnName] || filter.ColumnName
                    }`}
                    selectedOption={{
                      value: selectedFilters[filter.ColumnName],
                    }}
                    index={index + 1}
                    isSearchable
                    hasMultiSelect
                    hasSelectAll
                    beforeOpen={() => {
                      setActiveDropdown(index + 1);
                    }}
                    defaultOpenState={
                      activeDropdown === index + 1 ? true : false
                    }
                  />
                );
              } else if (filter.ColumnName === "Month") {
                const singleComponent = {
                  key: "month",
                  type: "dateInput",
                  inputType: "month",
                  name: "Month",
                  ...filter,
                };

                return (
                  <InputComponent
                    key={`${singleComponent.key}`}
                    filter={singleComponent}
                    handleChange={(event) => {
                      handleDateChange(event, filter);
                    }}
                    value={{ value: selectedFilters[filter.ColumnName] }}
                    isNumber={false}
                    isText={false}
                    index={index + 1}
                    hasRangeEnabled={false}
                  />
                );
              }
              return null;
            })}

          <ButtonComponent
            className="text-sm min-w-max h-fit mt-6 p-2 rounded-lg px-4 cursor-pointer bg-green-500 hover:bg-green-600 text-white"
            withoutBorder={false}
            id="corrective-results"
            color="green"
            disabled={!selectedFilters.hasOwnProperty("Month")}
            onClick={() => {
              const toastId = toast.loading(
                translate("reports.processing-download")
              );
              const newPendingID = addToDownloadCenter({
                href: "-",
                activityTag: `ExportReport-${selectedFilters.Month}`,
                done: false,
                apiEndpoint: "ExportReport",
                apiParams: selectedFilters,
                toastID: toastId,
                reportType: selectedReportType,
              });
              ExportReport(
                newPendingID,
                selectedFilters,
                (data) => {
                  console.log("🚀 ~ ReportsComponent ~ data:", data)
                  if (data.File) {
                    const fileName = extractFileName(data.File);
                    const fileSize = extractFileSize(data.File);
                    updateDownloadItem({
                      id: newPendingID,
                      newProps: {
                        href: data.File,
                        title: fileName,
                        fileSize: fileSize,
                        done: true,
                      },
                    });
                    toast.update(toastId, {
                      render: translate("reports.download-ready"),
                      type: "success",
                      isLoading: false,
                      autoClose: 3000,
                    });
                    setSelectedFilters({});
                    setSelectedReportType("");
                    ReportsModalStore.close();
                    DownloadModalStore.open(500);
                    DownloadModalStore.highlight(500, newPendingID);
                    window.open(data.File, "_blank");
                  }
                  // else {
                  //   const sfmData = GetSFMDisplay(data.SFM);
                  //   toast.update(toastId, {
                  //     render: `${sfmData.Message}`,
                  //     type: "warning",
                  //     isLoading: false,
                  //     autoClose: 5000,
                  //   });
                  // }
                },
                toastId,
                translate("download-center.actions.download-ready"),
                selectedReportType
              );
            }}
            ref={null}
            icon={null}
            text={translate("reports.exportButton")}
          />
        </div>
      )}
    </div>
  );
};

export default ReportsComponent;
